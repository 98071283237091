import { compose } from 'recompose';
import { ConfigService } from '../../services/config-service';
import { GEO_LOCATION_ENGLAND_REGIONS } from '../constants';
import { htbList, REPAYMENT_CALCULATION_TYPES } from './constants';
import { defaultCalculation, lbsCalculation } from './repayment-calculations';

const borrowCalculation = income => income * window.CONFIG.DEFAULT_VALUES.MORTGAGE_MAX_AMOUNT_TO_LEND_MULTIPLIER;

export const calculateMaxPrice = data => {
  const { buyersQuantity, ownIncome, theirIncome, depositPercent, htbPercent, selectedLocation, selectedMortgageType } =
    data;

  const income = buyersQuantity === 0 ? ownIncome : theirIncome + ownIncome;

  let maxHousePrice = Math.round((borrowCalculation(income) / (100 - depositPercent - htbPercent)) * 100);

  if (selectedMortgageType === 'Help to Buy Equity Loan') {
    const cap = setHtbCap(selectedLocation);

    if (maxHousePrice >= cap) {
      maxHousePrice = cap;
    }
  }

  return { ...data, maxHousePrice, housePrice: maxHousePrice };
};

const getRepaymentCalculationType = () => {
  const configuration = ConfigService.get('DEFAULT_VALUES.REPAYMENT_CALCULATION_TYPE', 'default');

  return REPAYMENT_CALCULATION_TYPES[configuration] ?? REPAYMENT_CALCULATION_TYPES.default;
};

const repaymentHandlersToType = {
  [REPAYMENT_CALCULATION_TYPES.default]: defaultCalculation,
  [REPAYMENT_CALCULATION_TYPES.lbs]: lbsCalculation
};

export const calculateRepayment = data => {
  const repaymentCalculationType = getRepaymentCalculationType();

  const repaymentHandler = repaymentHandlersToType[repaymentCalculationType];
  const repayment = repaymentHandler(data);

  return { ...data, repayment };
};

export const calculateBreakdown = (depositPercent, housePrice, htbPercent) => {
  const onePercent = housePrice / 100;

  return {
    deposit: Math.round(onePercent * depositPercent),
    equityLoanAmount: Math.round(onePercent * htbPercent),
    estimatedMortgageOffer: Math.round(onePercent * (100 - depositPercent - htbPercent))
  };
};

//expand and refactor locations here
export const setDefaultLocation = locationsParsed => {
  if (locationsParsed) {
    const { isEngland, isWales, isScotland, isNorthernIreland, regionOfEngland } = locationsParsed;

    if (isEngland) {
      return regionOfEngland;
    }

    if (isWales) {
      return GEO_LOCATION_ENGLAND_REGIONS.WALES;
    }

    if (isScotland || isNorthernIreland) {
      return void 0;
    }
  }

  return GEO_LOCATION_ENGLAND_REGIONS.LONDON;
};

export const setHtbPercentDueToLocation = location => {
  if (htbList[location]) {
    return htbList[location].percent;
  }

  return 20;
};

export const setHtbCap = location => {
  if (htbList[location]) {
    return htbList[location].cap;
  }

  return 200000;
};

export const mapPropsToHtb = (htb, props) => {
  const {
    ownIncome,
    buyersQuantity,
    housePrice,
    depositPercent,
    repayment,
    mortgageLength,
    mortgageRate,
    equityLoanAmount,
    estimatedMortgageOffer,
    theirIncome,
    mortgageTerm
  } = props;

  if (!htb) {
    return {
      ownIncome,
      buyersQuantity,
      secondAnnualIncome: theirIncome,
      propertyPrice: housePrice,
      depositPercent,
      repayment,
      mortgageLength,
      mortgageRate,
      estimatedMortgageOffer,
      mortgageTerm
    };
  }

  return {
    ownIncome,
    buyersQuantity,
    secondAnnualIncome: theirIncome,
    equityLoanAmount,
    HTBestimatedMortgageOffer: estimatedMortgageOffer,
    HTBpropertyPrice: housePrice,
    HTBdepositPercent: depositPercent,
    HTBrepayment: repayment,
    HTBmortgageLength: mortgageLength,
    HTBmortgageRate: mortgageRate,
    HTBmortgageTerm: mortgageTerm
  };
};

export const initialBorrowingCalculation = userAttributes => {
  const { MORTGAGE_LENGTH_DEFAULT, DEPOSIT_PERCENT_STANDARD, MORTGAGE_RATE_DEFAULT } = ConfigService.get(
    'DEFAULT_VALUES',
    {}
  );

  const goals = userAttributes.goal;

  const calculationState = {
    buyersQuantity: goals.buyersQuantity,
    ownIncome: goals.ownIncome,
    theirIncome: Number(goals.secondAnnualIncome) || 0,
    htbPercent: 0,
    selectedLocation: setDefaultLocation(goals.goalsLocationParsed),
    selectedMortgageType: 'Standard mortgage',
    mortgageTerm: goals.mortgageTerm || MORTGAGE_LENGTH_DEFAULT,
    depositPercent: goals.depositPercent || DEPOSIT_PERCENT_STANDARD,
    mortgageRate: goals.mortgageRate || MORTGAGE_RATE_DEFAULT
  };
  const data = compose(calculateRepayment, calculateMaxPrice)(calculationState);

  const { depositPercent, housePrice, htbPercent } = data;
  const extraProps = calculateBreakdown(depositPercent, housePrice, htbPercent);

  // The main reason to make these changes - to set propertyPrice=maxHousePrice when re-completing the flow.
  // And it should remain the same when just adjusting the wheels on result page
  return mapPropsToHtb(false, {
    ...data,
    ...extraProps,
    propertyPrice: data.maxHousePrice
  });
};
