import { TLoader } from '@apps/firsthomecoach/src/components/template';
import Loadable from 'react-loadable';

const DigitalCoach = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-digital-coach"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/digital-coach'
    ).then(module => module.DigitalCoach),
  loading: TLoader
});

const DashboardContent = Loadable({
  loader: () =>
    import(/* webpackChunkName: "dashboard-content"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/content').then(
      module => module.DashboardContent
    ),
  loading: TLoader
});

const DashboardAdverts = Loadable({
  loader: () =>
    import(/* webpackChunkName: "dashboard-adverts"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/adverts').then(
      module => module.DashboardAdverts
    ),
  loading: TLoader
});

const PositivePursuitsWidgetFF = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-vm-ff-positive-pursuits"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/future-fit-v2/positive-pursuits'
    ).then(module => module.PositivePursuitsWidgetFF),
  loading: TLoader
});

const HighPriorityGoalsWidgetFF = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-vm-ff-high-priority-goals"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/future-fit-v2/high-priority-goals'
    ).then(module => module.HighPriorityGoalsWidgetFF),
  loading: TLoader
});

const ScoreWidgetFF = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-vm-ff-score"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/future-fit-v2/score'
    ).then(module => module.ScoreWidgetFF),
  loading: TLoader
});

const MessagesWidgetFF = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-vm-ff-messages"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/future-fit-v2/messages'
    ).then(module => module.MessagesWidgetFF),
  loading: TLoader
});

const CategoryWidget = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-categories"*/ '@apps/firsthomecoach/src/pages/dashboard/widgets/categories'
    ).then(module => module.CategoryWidget),
  loading: TLoader
});

const DashboardContentRepeater = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard-vm-content-repeater"*/ '@apps/firsthomecoach/src/widgets/repeater/content-repeater'
    ).then(module => module.DashboardContentRepeater),
  loading: TLoader
});

export const dashboardWidgets = {
  digitalCoach: DigitalCoach,
  content: DashboardContent,
  adverts: DashboardAdverts,
  scoreFF: ScoreWidgetFF,
  messagesFF: MessagesWidgetFF,
  HPGFF: HighPriorityGoalsWidgetFF,
  PPFF: PositivePursuitsWidgetFF,
  categories: CategoryWidget,
  whatIsNew: DashboardContentRepeater, //TODO: remove, when widget configuration will be updated
  repeater: DashboardContentRepeater
};
