import { compose, withHandlers, withState } from 'recompose';
import { parse } from 'query-string';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { isNil, isUndefined } from '@packages/helpers/core/common';
import { connect } from 'react-redux';
import { changeLastPartOfPath } from '../../../helpers/utils';
import { GEO_LOCATION_FORMATTED_ADDRESS_ENGLAND } from '../../../helpers/constants';
import { fromMode, getLocationFromModeState, withSearchParams } from '../../../helpers/navigation/routing';
import { updateUser } from '../../../store/reducers/account/actions';
import { getStaticData } from '../../../store/reducers/static-data';
import { setGlobalConfig } from '../../../api/config';
import { pickRadioOptionByValue } from '../../../helpers/inputs/radio';
import { isInputValueOptional } from '../../../helpers/validation/schema-keys';
import { stringDateToISO } from '../../../helpers/time-format';
import { UserService } from '../../../services/user-service';
import { processAdditionalValues } from '../helpers/parse-additional-values';
import { INPUT_TYPES, INTRODUCER_ATTRIBUTE_FIELDS, INTRODUCER_ATTRIBUTE_TYPE } from '../helpers/constants';
import { validateCheckbox } from '../helpers/checkbox-validation';
import { withValue } from './with-value';
import { withQuestionState } from './with-question-state';

const mapHandlerByType = (type, event) => handlers => {
  const handler = handlers[type] ?? handlers.default;

  if (handler) return { [event]: handler };
  return {};
};

const withHelpers = withHandlers({
  goToNextQuestion:
    ({ history, location: { search, state }, data: { nextPageLink } }) =>
    () => {
      if (UserService.hasAccount()) {
        return;
      }
      //Prevents skipping questions when user is going to edit results
      const origin = getLocationFromModeState(state);

      history.push(fromMode(withSearchParams(nextPageLink, search), origin));
    }
});

const withSubmitHandler = withHandlers(props => {
  const { onSubmit, turnOnLoading, turnOffLoading } = props;

  if (onSubmit) {
    return {
      onSubmit:
        ({ data, submitValue }) =>
        () =>
          onSubmit({ data, value: submitValue })
    };
  }

  const {
    type,
    history,
    location: { pathname, search },
    data: { nextPageLink }
  } = props;

  const onGoalsLocationSubmit = response => {
    const { goalsLocation, goalsLocationParsed = false } = response;

    const isEngland = goalsLocation === GEO_LOCATION_FORMATTED_ADDRESS_ENGLAND;

    if (isEngland) {
      return history.push(changeLastPartOfPath(pathname, '/region-england'));
    }

    const isParsed = goalsLocationParsed && Object.values(goalsLocationParsed).some(item => item);

    if (isParsed) {
      const mode = search && parse(search).mode;
      const query = mode ? `?mode=${mode}` : '';

      return history.push(nextPageLink + query);
    }

    history.push(changeLastPartOfPath(pathname, '/region'));
  };
  const onLocationSubmit = (response, key) => {
    // API Endpoint is aimed to always return next interface
    // [userAttributeName]: <Object>: <Location>
    // [${userAttributeName}LocationParsed]: <Object>: <ParsedLocation>
    const { [key]: location, [`${key}LocationParsed`]: locationParsed = false } = response;

    const isEngland = location?.formatted_address === GEO_LOCATION_FORMATTED_ADDRESS_ENGLAND;

    if (isEngland) {
      return history.push(changeLastPartOfPath(pathname, '/region-england'));
    }

    const isParsed = locationParsed && Object.values(locationParsed).some(item => item);

    if (isParsed) {
      const mode = search && parse(search).mode;
      const query = mode ? `?mode=${mode}` : '';

      return history.push(nextPageLink + query);
    }

    history.push(changeLastPartOfPath(pathname, '/region'));
  };

  return mapHandlerByType(
    type,
    'onSubmit'
  )({
    [INPUT_TYPES.address]:
      ({ submitValue, data, sendUserAttribute, attributeType, sendStatusUpdate }) =>
      async () => {
        const { userAttributeField } = data;
        turnOnLoading();

        const sendRequest = sendUserAttribute(attributeType);
        const response = await sendRequest(submitValue[attributeType]);

        sendStatusUpdate();

        // TODO: Refactor it on API endpoint readiness.
        return userAttributeField === 'goalsGeolocation'
          ? onGoalsLocationSubmit(response)
          : onLocationSubmit(response, userAttributeField);
      },
    [INPUT_TYPES.button]:
      ({
        identifier,
        data,
        sendUserAttribute,
        sendAdditionalUserAttributes,
        goToNextQuestion,
        userAttributes,
        attributeType,
        sendStatusUpdate
      }) =>
      async () => {
        const { userAttributeField, userAttributeValue, redirectAfterSave, additionalValues, staticPayload } = data;

        const updatedUserAttributes = {
          [userAttributeField]: userAttributeValue,
          ...staticPayload
        };

        turnOnLoading();

        const sendMain = () => {
          if (userAttributeField) {
            const sendRequest = sendUserAttribute(attributeType);

            return sendRequest(updatedUserAttributes);
          }
        };

        const sendAdditional = async () => {
          if (additionalValues) {
            const processedValues = await processAdditionalValues(
              ObjectShim.mergeDeep(userAttributes, { [attributeType]: updatedUserAttributes }),
              additionalValues,
              { identifier }
            );
            if (processedValues) {
              return sendAdditionalUserAttributes(ObjectShim.deepen(processedValues), true);
            }
          }
        };

        // redirectAfterSave is mandatory if next question relies on data sent on previous question
        // e.g. pension finder question currently-employed which is right before company-name
        // relies on result of prev question
        if (redirectAfterSave) {
          //waiting for server to respond and redirect after all endpoints are resolved
          await sendMain();
          await sendAdditional();
          await sendStatusUpdate();
        } else {
          //not waiting for server response, optimistic UI approach
          sendMain();
          sendAdditional();
          sendStatusUpdate();
        }

        goToNextQuestion();
      },
    [INPUT_TYPES.groupParamsMarketingList]:
      ({
        identifier,
        submitValue,
        submitAdditionalValues,
        data,
        sendUserAttribute,
        sendUserGroupAttributes,
        sendAdditionalUserAttributes,
        goToNextQuestion,
        userAttributes,
        subscribeUnsubscribeMarketingList,
        sendStatusUpdate
      }) =>
      async () => {
        const { marketingListName } = data;

        const isOneType = Object.keys(submitValue).length === 1;

        turnOnLoading();

        const sendMain = () => {
          if (isOneType) {
            const [attributeType, data] = ObjectShim.entries(submitValue)[0];

            const sendRequest = sendUserAttribute(attributeType);
            return sendRequest(data);
          } else {
            return sendUserGroupAttributes(submitValue);
          }
        };

        const sendAdditional = async () => {
          if (submitAdditionalValues.length) {
            const processedValues = await processAdditionalValues(
              ObjectShim.mergeDeep(userAttributes, submitValue),
              submitAdditionalValues,
              { identifier }
            );
            if (processedValues) {
              return sendAdditionalUserAttributes(ObjectShim.deepen(processedValues), true);
            }
          }
        };

        const subscriptionMarketingList = async () => subscribeUnsubscribeMarketingList({ [marketingListName]: true });

        await sendMain();
        await sendAdditional();
        await sendStatusUpdate();

        await subscriptionMarketingList();

        goToNextQuestion();
      },
    [INPUT_TYPES.updateUser]:
      ({ goToNextQuestion, submitValue, sendUserAttribute, sendStatusUpdate, updateUser, data }) =>
      ({ setSubmitError, errorMessages, value }) =>
      async () => {
        turnOnLoading();

        const sendMain = async () => {
          const [attributeType, data] = ObjectShim.entries(submitValue)[0];

          const sendRequest = sendUserAttribute(attributeType);

          return sendRequest(data);
        };

        const { userField } = data;
        const response = await updateUser({ [userField]: value });

        if (response.errorMsg) {
          turnOffLoading();
          return setSubmitError(errorMessages?.request || response.errorMsg);
        }

        await sendMain();
        sendStatusUpdate();
        goToNextQuestion();
      },
    [INPUT_TYPES.introducer]:
      ({ goToNextQuestion, submitValue, sendUserAttribute, sendStatusUpdate, updateUser }) =>
      ({ setSubmitError, errorMessages, value }) =>
      async () => {
        turnOnLoading();

        const sendMain = brokerName => {
          const [, data] = ObjectShim.entries(submitValue)[0];
          const [, selectedItem] = ObjectShim.entries(data)[0];

          const sendRequest = sendUserAttribute(INTRODUCER_ATTRIBUTE_TYPE);
          return sendRequest({
            [INTRODUCER_ATTRIBUTE_FIELDS.introducer]: selectedItem.value,
            [INTRODUCER_ATTRIBUTE_FIELDS.brokerName]: brokerName
          });
        };

        const response = await updateUser({ introducerCode: value });

        if (response.errorMsg) {
          turnOffLoading();
          return setSubmitError(errorMessages?.request || response.errorMsg);
        }

        await sendMain(response.introducer?.brokerName);

        //Temporary solution, might be replaced when the logic of fetching introducer-specific style is approved
        await setGlobalConfig(value);

        sendStatusUpdate();
        goToNextQuestion();
      },
    [INPUT_TYPES.introducerAutocomplete]:
      ({ goToNextQuestion, submitValue, sendUserAttribute, sendStatusUpdate, updateUser }) =>
      async () => {
        turnOnLoading();

        const [, data] = ObjectShim.entries(submitValue)[0];
        const [, selectedItem] = ObjectShim.entries(data)[0];

        const sendMain = async () => {
          const sendRequest = sendUserAttribute(INTRODUCER_ATTRIBUTE_TYPE);
          return sendRequest({
            [INTRODUCER_ATTRIBUTE_FIELDS.broker]: selectedItem.value,
            [INTRODUCER_ATTRIBUTE_FIELDS.brokerName]: selectedItem.name,
            [INTRODUCER_ATTRIBUTE_FIELDS.introducer]: selectedItem.key
          });
        };

        await updateUser({ introducerCode: selectedItem.key });

        await sendMain();

        //Temporary solution, might be replaced when the logic of fetching introducer-specific style is approved
        await setGlobalConfig(selectedItem.key);

        sendStatusUpdate();
        goToNextQuestion();

        turnOffLoading();
      },
    [INPUT_TYPES.radio]:
      ({
        identifier,
        submitValue,
        submitAdditionalValues,
        data,
        sendUserAttribute,
        sendUserGroupAttributes,
        sendAdditionalUserAttributes,
        goToNextQuestion,
        userAttributes,
        sendStatusUpdate
      }) =>
      async () => {
        const { redirectAfterSave } = data;

        const isOneType = Object.keys(submitValue).length === 1;

        turnOnLoading();

        const sendMain = () => {
          if (isOneType) {
            const entry = ObjectShim.entries(submitValue)[0];
            const [attributeType, data] = entry;

            const sendRequest = sendUserAttribute(attributeType);
            return sendRequest(data);
          } else {
            return sendUserGroupAttributes(submitValue);
          }
        };

        const sendAdditional = async () => {
          if (submitAdditionalValues.length) {
            const processedValues = await processAdditionalValues(
              ObjectShim.mergeDeep(userAttributes, submitValue),
              submitAdditionalValues,
              { identifier }
            );
            if (processedValues) {
              return sendAdditionalUserAttributes(ObjectShim.deepen(processedValues), true);
            }
          }
        };

        // Expanding, onSubmit logic with ability to send specific statusData {activityKey, activityStatusKey} on selected radio item, if set.
        // Used for buying vouchers
        // works only as an alone input (not in GroupInput)
        const sendOptionStatusUpdate = async () => {
          const option = pickRadioOptionByValue(data, submitValue);

          if (option?.statusData) {
            await sendStatusUpdate(option.statusData);
          }
        };

        // redirectAfterSave is mandatory if next question relies on data sent on previous question
        // e.g. pension finder question currently-employed which is right before company-name
        // relies on result of prev question
        if (redirectAfterSave) {
          //waiting for server to respond and redirect after all endpoints are resolved
          await sendMain();
          await sendAdditional();
          await sendOptionStatusUpdate();
          await sendStatusUpdate();
        } else {
          //not waiting for server response, optimistic UI approach
          sendMain();
          sendAdditional();
          sendOptionStatusUpdate();
          sendStatusUpdate();
        }

        goToNextQuestion();
      },
    default:
      ({
        identifier,
        submitValue,
        submitAdditionalValues,
        data,
        sendUserAttribute,
        sendUserGroupAttributes,
        sendAdditionalUserAttributes,
        goToNextQuestion,
        userAttributes,
        sendStatusUpdate
      }) =>
      async () => {
        const { redirectAfterSave } = data;

        const isOneType = Object.keys(submitValue).length === 1;

        turnOnLoading();

        const sendMain = () => {
          if (isOneType) {
            const entry = ObjectShim.entries(submitValue)[0];
            const [attributeType, data] = entry;

            const sendRequest = sendUserAttribute(attributeType);
            return sendRequest(data);
          } else {
            return sendUserGroupAttributes(submitValue);
          }
        };

        const sendAdditional = async () => {
          if (submitAdditionalValues.length) {
            const processedValues = await processAdditionalValues(
              ObjectShim.mergeDeep(userAttributes, submitValue),
              submitAdditionalValues,
              { identifier }
            );
            if (processedValues) {
              return sendAdditionalUserAttributes(ObjectShim.deepen(processedValues), true);
            }
          }
        };

        // redirectAfterSave is mandatory if next question relies on data sent on previous question
        // e.g. pension finder question currently-employed which is right before company-name
        // relies on result of prev question
        if (redirectAfterSave) {
          //waiting for server to respond and redirect after all endpoints are resolved
          await sendMain();
          await sendAdditional();
          await sendStatusUpdate();
        } else {
          //not waiting for server response, optimistic UI approach
          sendMain();
          sendAdditional();
          sendStatusUpdate();
        }

        goToNextQuestion();
      }
  });
});

const withChangeHandler = withHandlers(props => {
  const { onChange } = props;

  if (onChange) {
    return {
      onChange:
        ({ data, value }) =>
        () =>
          onChange({ data, value })
    };
  }

  const { type, validateValue } = props;

  return mapHandlerByType(
    type,
    'onChange'
  )({
    [INPUT_TYPES.wheel]:
      ({ setValue, setFormValue }) =>
      () =>
      value => {
        validateValue(value);
        setFormValue(value);
        setValue(value);
      },
    [INPUT_TYPES.checkbox]:
      ({ value, setValue, setFormValue, onError }) =>
      ({ name, boundValue, label }, skipErrorSetting = false) =>
      e => {
        const checkboxValue = { ...value, [name]: { checked: e ? e.target.checked : false, value: boundValue, label } };

        const error = validateValue(checkboxValue);

        //Error does not need to be shown when additionalValueOnFalse is configured
        if (!skipErrorSetting) {
          onError(error);
        }

        setFormValue(checkboxValue);
        //Allow setting additionalValueOnFalse for several checkbox options
        setValue(prevVal => ({
          ...prevVal,
          ...checkboxValue
        }));
      },
    [INPUT_TYPES.radio]:
      ({ setValue, setFormValue, setSubmitAdditionalValues }) =>
      ({ name, boundValue, usePlainValue, additionalValues, label }) =>
      e => {
        const fullValue = { [name]: { checked: e ? e.target.checked : false, value: boundValue, label } };
        const value = usePlainValue ? boundValue : fullValue;

        validateValue(value);
        setFormValue(value);
        setValue(value);

        if (additionalValues) {
          setSubmitAdditionalValues(additionalValues);
        }
      },
    [INPUT_TYPES.segmentedButton]:
      ({ setValue, setFormValue, plainValue }) =>
      (boundValue, name, label) => {
        const value = plainValue ? boundValue : { [name]: { checked: true, value: boundValue, label } };

        validateValue(value);
        setFormValue(value);
        setValue(value);
      },
    [INPUT_TYPES.select]:
      ({ setValue, setFormValue }) =>
      () =>
      value => {
        validateValue(value);
        setFormValue(value);
        setValue(value);
      },
    [INPUT_TYPES.priorityGoalSelect]:
      ({ setValue, setFormValue }) =>
      () =>
      value => {
        validateValue(value);
        setFormValue(value);
        setValue(value);
      },
    [INPUT_TYPES.updateUser]:
      ({ setValue, setFormValue }) =>
      ({ setSubmitError }) =>
      value => {
        setSubmitError(null);
        setFormValue(value);
        validateValue(value);
        setValue(value);
      },
    [INPUT_TYPES.introducer]:
      ({ setValue, setFormValue }) =>
      ({ setSubmitError }) =>
      value => {
        setSubmitError(null);
        setFormValue(value);
        validateValue(value);
        setValue(value);
      },
    [INPUT_TYPES.date]:
      ({ setValue, setFormValue, saveISOFormat, format }) =>
      value => {
        validateValue(value);
        setFormValue(saveISOFormat ? stringDateToISO(value, format) : value);
        setValue(value);
      },
    default:
      ({ setValue, setFormValue }) =>
      value => {
        validateValue(value);
        setFormValue(value);
        setValue(value);
      }
  });
});

const withInputHandler = withHandlers(props => {
  const { onInput } = props;

  if (onInput) {
    return {
      onInput:
        ({ data, value }) =>
        () =>
          onInput({ data, value })
    };
  }

  const { type } = props;

  return mapHandlerByType(
    type,
    'onInput'
  )({
    [INPUT_TYPES.checkbox]:
      ({ value, setValue, setFormValue }) =>
      name =>
      inputValue => {
        const newVal = {
          ...value,
          [name]: {
            text: inputValue,
            checked: true
          }
        };
        setFormValue(newVal);
        setValue(newVal);
      },
    [INPUT_TYPES.radio]:
      ({ setValue, setFormValue }) =>
      name =>
      inputValue => {
        const newVal = {
          [name]: {
            text: inputValue,
            checked: true
          }
        };
        setFormValue(newVal);
        setValue(newVal);
      }
  });
});

// TODO: Will be good to move validation for the rest inputs here as well
const withValidationHandler = compose(
  withHandlers({
    setFormDisabledItem: ({ setFormDisabledItems, ...props }) => setFormDisabledItems(props)
  }),
  withHandlers(props => {
    const { type } = props;

    return mapHandlerByType(
      type,
      'validateValue'
    )({
      [INPUT_TYPES.checkbox]:
        ({ setFormDisabledItem, requiredAll, items, max, min, defaultValue, isOptional, errorMessages }) =>
        value => {
          if (isInputValueOptional(isOptional)) return setFormDisabledItem(false);

          if (isNil(value)) return setFormDisabledItem(true);

          const error = validateCheckbox(value, { max, min, requiredAll, items, defaultValue, errorMessages });

          setFormDisabledItem(!!error);
          return error;
        },
      [INPUT_TYPES.radio]:
        ({ setFormDisabledItem, items, plainValue, isOptional }) =>
        value => {
          if (isInputValueOptional(isOptional)) return setFormDisabledItem(false);

          const isDisabledItem = plainValue
            ? isNil(value) || !items.some(item => item.value === value)
            : isNil(value) || !Object.values(value).some(item => item.checked);

          setFormDisabledItem(isDisabledItem);
        },
      [INPUT_TYPES.segmentedButton]:
        ({ setFormDisabledItem, isOptional, items, plainValue }) =>
        value => {
          if (isInputValueOptional(isOptional)) return setFormDisabledItem(false);

          const isDisabledItem = plainValue
            ? isNil(value) || !items.some(item => item.value === value || item.label === value)
            : isNil(value) || !Object.values(value).some(item => item.checked);
          setFormDisabledItem(isDisabledItem);
        },
      [INPUT_TYPES.number]:
        ({ setFormDisabledItem, isOptional }) =>
        value => {
          if (isInputValueOptional(isOptional)) return setFormDisabledItem(false);

          setFormDisabledItem(isUndefined(value) || isNaN(value));
        },
      [INPUT_TYPES.select]:
        ({ setFormDisabledItem, isOptional }) =>
        value => {
          if (isInputValueOptional(isOptional)) return setFormDisabledItem(false);

          setFormDisabledItem(!value);
        },
      default:
        ({ setFormDisabledItem, isOptional }) =>
        value => {
          if (isInputValueOptional(isOptional)) return setFormDisabledItem(false);

          setFormDisabledItem(isUndefined(value));
        }
    });
  })
);

const withErrorHandler = compose(
  withHandlers({
    setFormErrorItem: ({ setFormErrorItems, ...props }) => setFormErrorItems(props)
  }),
  withState('error', 'setError', null),
  withHandlers(props => {
    const { type } = props;

    return mapHandlerByType(
      type,
      'onError'
    )({
      default:
        ({ setFormDisabledItem, setFormErrorItem, setError }) =>
        error => {
          setError(error);
          setFormErrorItem(error);
          setFormDisabledItem(!!error);
        }
    });
  })
);

export const withInputHandlers = compose(
  withQuestionState,
  withValidationHandler,
  withErrorHandler,
  withValue,
  withChangeHandler,
  withInputHandler
);

export const withSubmitHandlers = compose(
  connect(({ userAttributes }) => ({ userAttributes }), { getStaticData, updateUser }),
  withHelpers,
  withSubmitHandler
);
