import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { branch, compose, renderNothing, withPropsOnChange } from 'recompose';
import styles from '../../styles/components/breadcrumbs.module.scss';
import { ConfigService } from '../../services/config-service';
import { buildBreadcrumbItems } from '../../helpers/breadcrumbs';
import { Column, Grid } from '../layout/grid';
import { BreadcrumbsComponent } from './component';

const withWrapper =
  Component =>
  ({ edge, grid, forwardRef, ...props }) =>
    (
      <Grid className={styles.wrapper} ref={forwardRef}>
        <Column flex justify='left' edge={edge}>
          <Component {...props} />
        </Column>
      </Grid>
    );

export const Breadcrumbs = compose(
  branch(() => !ConfigService.get('DISPLAY_COMPONENTS.BREADCRUMBS', false), renderNothing),
  withRouter,
  withPropsOnChange(['pageType'], ({ pageType }) => {
    const isVisibleConfig = ConfigService.get('BREADCRUMBS.isVisible', {});

    return {
      isVisible: isVisibleConfig[pageType]
    };
  }),
  branch(({ isVisible }) => !isVisible, renderNothing),
  withWrapper,
  withPropsOnChange(['match'], ({ match }) => ({
    items: buildBreadcrumbItems(match)
  }))
)(BreadcrumbsComponent);

Breadcrumbs.displayName = 'Breadcrumbs';

Breadcrumbs.propTypes = {
  pageType: PropTypes.string.isRequired
};
