import PropTypes from 'prop-types';
import React, { useLayoutEffect } from 'react';
import { Column } from '@packages/ui-kit/core/grid';
import { CategoryFilterComponent } from '../../components/layout/category-filter';
import { Header } from '../../components/layout/page-header';
import { AffiliateIcon } from '../../components/icons/affiliate-icon';
import { Footer } from '../../components/layout/page-footer';
import { MetaTags } from '../../components/meta-tags';
import { useScrollRestoration } from '../../components/scroll-position/hooks/use-scroll-restoration';
import { Template } from '../../components/template';
import styles from '../../styles/templates/guidance-template.module.scss';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { VISIBILITY_PAGE_TYPES } from '../../helpers/pages/constants';
import { IdentifierConfigPropTypes } from '../../components/layout/page-header/components/identifier';
import { GoalsList } from './components/goals-list';
import { GoalsListHeader } from './components/goals-list-header';

const GoalsTemplate = React.memo(props => {
  const {
    pageName,
    pageHeader,
    emptyCategoryText,
    emptyStateText,
    goalsItems,
    goalsLoading,
    selectedCategory,
    categoriesOptions,
    handleChangeCategory,
    listHeaders
  } = props;

  const { restoreScrollPosition } = useScrollRestoration();

  useLayoutEffect(() => {
    if (!goalsLoading) {
      restoreScrollPosition();
    }
  }, [goalsLoading, restoreScrollPosition]);

  if (goalsLoading) {
    return (
      <Column edge className={styles.centered}>
        <AffiliateIcon name='loading' />
      </Column>
    );
  }

  return (
    <>
      <MetaTags name={pageName} />
      {pageHeader?.isVisible && (
        <Header
          className={styles.header}
          heading={pageHeader?.heading}
          subheading={pageHeader?.subheading}
          category={pageHeader?.category}
          type={pageHeader?.type}
          backgroundPosition={pageHeader?.backgroundPosition}
          showBackButton={pageHeader?.showBackButton}
          identifier={pageHeader?.identifier}
        />
      )}
      <Breadcrumbs pageType={VISIBILITY_PAGE_TYPES.goals} />
      <Template className={styles.grid}>
        <Column edge>
          <CategoryFilterComponent
            values={categoriesOptions}
            select={selectedCategory}
            handleSelect={handleChangeCategory}
          />
        </Column>
        <Column edge>
          <GoalsListHeader FAQGroupName='Goals' listHeaderConfig={listHeaders} />
        </Column>
        <Column edge>
          <GoalsList goalsItems={goalsItems} emptyText={!selectedCategory ? emptyStateText : emptyCategoryText} />
        </Column>
      </Template>
      <Footer pageType={VISIBILITY_PAGE_TYPES.goals} />
    </>
  );
});

GoalsTemplate.propTypes = {
  pageName: PropTypes.string.isRequired,
  pageHeader: PropTypes.shape({
    category: PropTypes.string.isRequired,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    type: PropTypes.string,
    backgroundPosition: PropTypes.string,
    showBackButton: PropTypes.string,
    isVisible: PropTypes.bool,
    identifier: PropTypes.shape(IdentifierConfigPropTypes)
  }),
  emptyCategoryText: PropTypes.string.isRequired,
  emptyStateText: PropTypes.string.isRequired,
  goalsItems: PropTypes.arrayOf(PropTypes.shape(GoalsList.propTypes)).isRequired,
  goalsLoading: PropTypes.bool.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  categoriesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      }),
      disabled: PropTypes.bool.isRequired
    })
  ).isRequired,
  handleChangeCategory: PropTypes.func.isRequired,
  listHeaders: PropTypes.exact({
    descriptionColumn: PropTypes.string,
    scoreColumn: PropTypes.string,
    actionsColumn: PropTypes.string
  })
};

GoalsTemplate.displayName = 'GoalsTemplate';

export default GoalsTemplate;
