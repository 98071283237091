import React from 'react';
import { Footer } from '../../components/layout/page-footer';
import { Header } from '../../components/layout/page-header';
import { Spacer } from '../../components/layout/spacers';
import { Template } from '../../components/template';
import { CARD_LOCATION_PAGE_TYPES } from '../../helpers/repeaters/constants';
import { ConfigService } from '../../services/config-service';
import { PAGE_TYPES_TO_CONFIG_NAMES } from '../../helpers/constants';
import { DashboardWidget } from './widgets';

export const DefaultDashboard = React.memo(({ widgetsToShow, widgets }) => {
  const {
    DASHBOARD: { pageHeader, topSpacer, bottomSpacer },
    PAGE_HEADER: { ICONS }
  } = ConfigService.get();

  return (
    <>
      {pageHeader?.isVisible ? (
        <Header
          icon={ICONS?.DASHBOARD}
          image={pageHeader?.image}
          name={pageHeader?.name}
          heading={pageHeader?.title}
          category={pageHeader?.category}
          subheading={pageHeader?.subtitle}
          subBlockName={pageHeader?.subBlockName}
          headerButtons={pageHeader?.headerButtons}
          alt={pageHeader?.alt}
          type={pageHeader?.type}
          backgroundPosition={pageHeader?.backgroundPosition}
          showBackButton={pageHeader?.showBackButton}
          pageConfigKey={PAGE_TYPES_TO_CONFIG_NAMES.dashboard}
          withKeyline
        />
      ) : null}
      <Template>
        {topSpacer && <Spacer {...topSpacer} />}
        {widgetsToShow.map(([name, widget], index) => (
          <DashboardWidget
            key={`${name}_${index}`}
            name={name}
            widgets={widgets}
            pageType={CARD_LOCATION_PAGE_TYPES.dashboard}
            order={index + 1}
            {...widget}
          />
        ))}
        {bottomSpacer && <Spacer {...bottomSpacer} />}
      </Template>
      <Footer pageType='dashboard' />
    </>
  );
});
