export const DATE_FORMAT = 'DD/MM/YYYY';

export const BUYERS_QUANTITY = {
  ON_MY_OWN: 0,
  WITH_SOMEONE: 1
};

export const HOME_OR_RENTED_OUT = {
  HOME: 'home',
  RENTED_OUT: 'rented-out'
};

export const FHC_FLOWS = {
  PLAN: 'plan',
  DEPOSIT_BUILDER: 'depositBuilder',
  BORROWING_STANDARD: 'how-much-can-i-borrow',
  BORROWING_HELP_TO_BUY: 'help-to-buy'
};

export const FHC_MODULE_STATUSES = {
  TO_DO: 'notStarted',
  SEEN: 'seen',
  IN_PROGRESS: 'inProgress',
  SNOOZED: 'snooze',
  NOT_APPLICABLE: 'notApplicable',
  NOT_KEEN: 'notKeen',
  ALREADY_HAVE_ONE: 'alreadyHaveOne',
  COMPLETED: 'completed'
};

export const GEO_LOCATION_REGIONS = {
  ENGLAND: 'England',
  LONDON: 'London',
  SCOTLAND: 'Scotland',
  WALES: 'Wales',
  NI: 'Northern Ireland'
};

export const GEO_LOCATION_ENGLAND_REGIONS = {
  LONDON: 'Greater London',
  NORTH_EAST_ENGLAND: 'North East England',
  NORTH_WEST_ENGLAND: 'North West England',
  SOUTH_EAST_ENGLAND: 'South East England',
  SOUTH_WEST_ENGLAND: 'South West England',
  YORKSHIRE: 'Yorkshire and the Humber',
  EAST_MIDLANDS: 'East Midlands',
  WEST_MIDLANDS: 'West Midlands',
  EAST_ENGLAND: 'East of England',
  WALES: 'Wales'
};

export const GEO_LOCATION_FORMATTED_ADDRESS_ENGLAND = 'England, UK';
export const ROUTE_SEPARATOR = '/';

export const CONSENTS_TYPES = {
  MANAGED: 'MANAGED',
  UNMANAGED: 'UNMANAGED'
};

export const CONSENT_COOKIE_TYPES = {
  promotions: 'promotions',
  essential: 'essential',
  tracking: 'tracking',
  advertising: 'advertising'
};

export const CUSTOM_PAGE_TYPE = {
  START_PAGE: 'StartPage',
  RESULT_PAGE: 'ResultPage',
  INFO_PAGE: 'InfoPage'
};

export const PAGE_TRIGGER_TO_PAGE_TYPE = {
  start: CUSTOM_PAGE_TYPE.START_PAGE,
  result: CUSTOM_PAGE_TYPE.RESULT_PAGE,
  info: CUSTOM_PAGE_TYPE.INFO_PAGE
};

export const CUSTOM_PAGE_TYPE_TO_CONFIG = {
  StartPage: 'START_PAGE',
  ResultPage: 'RESULT_PAGE',
  InfoPage: 'INFO_PAGE'
};

export const ORDER_TYPES = {
  asc: 'asc',
  desc: 'desc',
  default: 'asc'
};

export const TRACKING_STATUSES = {
  APP_INIT: 'appInit',
  APP_RENDER: 'appRender',
  WIDGET_LOADED: 'widgetLoaded',
  WIDGET_SEEN: 'widgetSeen',
  LOCATION_CHANGED: 'locationChanged'
};

export const POST_TYPES = {
  article: 'article',
  blog: 'blog',
  announcement: 'announcement',
  project: 'project'
};

export const CONTENT_TYPES = {
  ...POST_TYPES,
  story: 'story',
  video: 'video',
  activity: 'activity'
};

export const PAGE_TYPES_TO_CONFIG_NAMES = {
  articlesHomePage: 'ARTICLES_HOME_PAGE',
  blogsHomePage: 'BLOGS_HOME_PAGE',
  feed: 'FEED',
  dashboard: 'DASHBOARD',
  questions: 'QUESTIONS',
  [CONTENT_TYPES.article]: 'ARTICLE',
  [CONTENT_TYPES.blog]: 'BLOG',
  [CONTENT_TYPES.announcement]: 'BLOG', // It is not a mistake, announcement is a blog
  [CONTENT_TYPES.project]: 'PROJECT',
  [CONTENT_TYPES.story]: 'STORIES',
  [CONTENT_TYPES.video]: 'VIDEOS',
  ...CUSTOM_PAGE_TYPE_TO_CONFIG
};
